import React, { ReactElement } from "react";

import { usePrivacyPolicy } from "../hooks/usePrivacyPolicy";

export interface PrivacyPolicy {
  success: boolean;
  content: string;
}

const Index = (): ReactElement => {
  const privacyPolicy = usePrivacyPolicy();
  return (
    <div
      style={{ padding: "15px" }}
      dangerouslySetInnerHTML={{
        __html:
          privacyPolicy?.data?.content || privacyPolicy.error?.message || "",
      }}
    />
  );
};

export default Index;
